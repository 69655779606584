import React, {useCallback, useContext, useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'
import {useHttp} from '../hooks/http.hook'
import {AuthContext} from '../context/AuthContext'
import config from '../config.json'
import {
    objectLang,
    setDataModalFromCatch,
    setErrorWindow
} from "../functions"
import {ModalWin} from "../elements/Modal"
import {LoaderClass} from "../elements/LoaderClass"
import $ from "jquery"
import {LinkCardClass} from "../components/LinkCardClass"

export const LinkDetailPage = () => {
    localStorage.setItem('page', 'LinkDetailPage')

    const auth = useContext(AuthContext)
    const {request, loading} = useHttp()
    const [data, setData] = useState(null)
    const [allowed, setAllowed] = useState(true)
    const [dataModal, setDataModal] = useState({})
    const [allowedCatch, setAllowedCatch] = useState(true)
    const [dataModalCatch, setDataModalCatch] = useState({})
    const linkId = useParams().id
    let lang = objectLang().language

    const getLink = useCallback(async () => {
        let host
        window.location.host === 'localhost:3000' ? host = config.localHost : host = config.heroku
        try {
            const data = await request(
                `${host}/api/link/detail/${linkId}`,
                'GET',
                null,
                {
                    Authorization: `Bearer ${auth.accessToken} RefreshToken ${auth.refreshToken} DeviceId ${auth.deviceId}`
                })
            if (data.status > 201) {
                setAllowed(false)
                setDataModal(setErrorWindow('', data.code))
            }

            const link = data.link
            const password = link.password
            if (password !== '') {
                link.password = atob(password)
            }
            setData({link: link, owner: data.owner})
            // setDocuments({path: link.path})
        } catch (e) {
            setAllowedCatch(false)
            setDataModalCatch(setDataModalFromCatch(e))
        }
    }, [auth, linkId, request])

    useEffect(() => {
        getLink().then()
    }, [getLink])

    const updateHandler = () => {
        $('#share-checkbox').attr('disabled', false)
        $('#from').attr('disabled', false)
        $('#name').attr('disabled', false)
        $('#description').attr('disabled', false)
        $('#login').attr('disabled', false)
        $('#password').attr('disabled', false)
        $('.eye-detail').css('background-color', 'white')
    }

    const removeLinkHandler = (link) => {
        lang = objectLang().language
        setDataModal({
            title: lang.modal.warnings.title,
            desc: lang.modal.warnings.desc.deleteLink,
            btnCancel: lang.modal.warnings.btnCancel,
            btnOK: lang.modal.warnings.btnOK,
            clickOK: `replaceTo /link/remove/${link["_id"]}`,
            clickCancel: 'reload'
        })
        setAllowed(false)
    }

    const saveHandler = async () => {
        let host
        window.location.host === 'localhost:3000' ? host = config.localHost : host = config.heroku
        try {
            const data = await request(`${host}/api/link/update/${linkId}`, 'POST',
                {
                    from: $('#from').val(),
                    name: $('#name').val(),
                    description: $('#description').val(),
                    login: $('#login').val(),
                    password: btoa($('#password').val())
                },
                {
                    Authorization: `Bearer ${auth.accessToken} RefreshToken ${auth.refreshToken} DeviceId ${auth.deviceId}`
                })
            if (data.status > 201) {
                setAllowed(false)
                setDataModal(setErrorWindow('', data.code))
            } else {
                window.location.reload()
            }
        } catch (e) {
            setAllowedCatch(false)
            setDataModalCatch(setDataModalFromCatch(e))
        }
    }

    if (loading) {
        return <LoaderClass />
    }

    if (!allowed) {
        return (
            <>
                <ModalWin data={dataModal}/>
            </>

        )
    }

    if (!allowedCatch) {
        return (
            <ModalWin data={dataModalCatch}/>
        )
    }

    return (
        <>
            <div className={'container'}>
                {!loading && data &&
                <LinkCardClass
                    link={data.link}
                    owner={data.owner}
                    updateHandler={updateHandler}
                    removeLinkHandler={removeLinkHandler}
                    saveHandler={saveHandler}
                />}
            </div>
        </>

    )
}
