import React from "react"
import {Link} from "react-router-dom"
import {changeHandler, eyeHandler, formatDate, objectLang} from "../functions"
import $ from "jquery"

export class LinkCardClass extends React.Component {

    render() {

        const {link, owner, updateHandler, removeLinkHandler, saveHandler} = {
            link: this.props.link,
            owner: this.props.owner,
            updateHandler: this.props.updateHandler,
            removeLinkHandler: this.props.removeLinkHandler,
            saveHandler: this.props.saveHandler,
        }

        const linkData = {
            name: link.name,
            description: link.description,
            login: link.login,
            password: link.password
        }

        const lang = objectLang().language

        let formDetailFrom, formDetailName, formDetailDescription, formDetailLogin, formDetailPassword
        link.from ? formDetailFrom = 'form-detail' : formDetailFrom = ''
        linkData.name ? formDetailName = 'form-detail' : formDetailName = ''
        link.description ? formDetailDescription = 'form-detail' : formDetailDescription = ''
        link.login ? formDetailLogin = 'form-detail' : formDetailLogin = ''
        link.password ? formDetailPassword = 'form-detail' : formDetailPassword = ''

        return (
            <>
                <div className={'container col col-sm-12 col-xl-6'}>
                    <form className={'rounded'}>
                        <div className={"mb-3 mt-3 d-flex justify-content-center"}>
                            <Link to={`${link.from}`}
                                  target={"_blank"}
                                  className={"btn btn-success fs-3"}>
                                {linkData.name}
                            </Link>
                        </div>
                        <p
                            id={"p-date-created"}
                            className={"mt-3 ms-3 mb-1"}>
                            {`${lang.body.linkPage.createdDate}`}&nbsp;
                            <strong>{formatDate(link["dateCreated"]).date}</strong>
                        </p>
                        <fieldset className={'teal m-3 p-3'}>

                            <div id={"divFrom"} className={"mb-1"}>
                                <label
                                    id={"owner-label"}
                                    htmlFor={"owner"}
                                    className={"form-label"}>
                                    {`Владелец ссылки`}
                                </label>
                                <h4
                                    id={"owner"}
                                    className={`mb-3`}
                                    placeholder={`${lang.body.linkPage.placeholderSiteLink}`}>
                                    {`${owner.name}`}
                                </h4>
                            </div>

                            <div id={"divFrom"} className={"mb-1"}>
                                <label
                                    id={"label-from"}
                                    htmlFor={"from"}
                                    className={"form-label"}>
                                    {`${lang.body.linkPage.labelSiteLink}`}
                                </label>
                                <input
                                    disabled
                                    name={"from"}
                                    id={"from"}
                                    defaultValue={`${link.from}`}
                                    onChange={changeHandler}
                                    className={`${formDetailFrom} form-control`}
                                    placeholder={`${lang.body.linkPage.placeholderSiteLink}`}
                                />
                            </div>
                            <div id={"divName"} className={"mb-3"}>
                                <label
                                    id={"label-name"}
                                    htmlFor={"name"}
                                    className={"form-label"}>
                                    {`${lang.body.linkPage.labelSiteTitle}`}
                                </label>
                                <input
                                    disabled
                                    type={"text"}
                                    name={"name"}
                                    id={"name"}
                                    defaultValue={`${linkData.name}`}
                                    onChange={changeHandler}
                                    className={`${formDetailName} form-control`}
                                    placeholder={`${lang.body.linkPage.placeholderSiteTitle}`}
                                />
                            </div>
                            <div id={"divDescription"} className={"mb-3"}>
                                <label
                                    id={"label-description"}
                                    htmlFor={"description"}
                                    className={"form-label"}>
                                    {`${lang.body.linkPage.labelSiteDescription}`}
                                </label>
                                <textarea
                                    disabled
                                    name={"description"}
                                    id={"description"}
                                    defaultValue={linkData.description}
                                    onChange={changeHandler}
                                    className={`${formDetailDescription} form-control`}
                                    placeholder={`${lang.body.linkPage.placeholderSiteDescription}`}
                                />
                            </div>
                            <div id={"divLogin"} className={"mb-3"}>
                                <label
                                    id={"label-login"}
                                    htmlFor={"login"}
                                    className={"form-label"}>
                                    {`${lang.body.linkPage.labelLoginSite}`}
                                </label>
                                <input
                                    disabled
                                    type={"text"}
                                    name={"login"}
                                    id={"login"}
                                    defaultValue={`${linkData.login}`}
                                    onChange={changeHandler}
                                    className={`${formDetailLogin} form-control`}
                                    placeholder={`${lang.body.linkPage.placeholderLoginSite}`}
                                />
                            </div>
                            <div id={"divPassword"} className={"mb-3"}>
                                <label
                                    id={"label-password"}
                                    htmlFor={"password"}
                                    className={"form-label"}>
                                    {`${lang.body.linkPage.labelPasswordSite}`}
                                </label>
                                <div className={"input-group"}>
                                    <input
                                        disabled
                                        type={"password"}
                                        name={"password"}
                                        id={"password"}
                                        defaultValue={`${linkData.password}`}
                                        onChange={changeHandler}
                                        className={`${formDetailPassword} form-control form-detail-password`}
                                        placeholder={`${lang.body.linkPage.placeholderPasswordSite}`}
                                    />
                                    <div className={"input-group-prepend"}>
                                        <div className={"div-eye input-group-text eye-detail"}>
                                            <Link to={``}
                                                  className={"eye-detail"}
                                                  onClick={() => {
                                                      eyeHandler($('#eye-open-close'), $('#password'))
                                                  }}>
                                                <i id={"eye-open-close"} className={"fa-regular fa-eye-slash"}/>
                                            </Link>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className={"ms-1 me-1 row row-cols-xl-5 gap-xl-2 justify-content-center"}>
                                <Link to={''}
                                      id={"btn-back"}
                                      className={"form-detail-btn btn btn-info mt-2"}
                                      onClick={() => {
                                          window.location.replace(localStorage.getItem('pathBack'))
                                      }}>
                                    {`${lang.body.linkPage.buttonBack}`}
                                </Link>
                                <Link to={`/link/detail/${link["_id"]}`}
                                      id={"btn-save"}
                                      className={"form-detail-btn btn btn-success disabled mt-2"}
                                      onClick={saveHandler}>
                                    {`${lang.body.linkPage.buttonSave}`}
                                </Link>
                                <Link to={`/link/detail/${link["_id"]}`}
                                      id={"btn-update"}
                                      className={"form-detail-btn btn btn-warning mt-2"}
                                      onClick={updateHandler}>
                                    {`${lang.body.linkPage.buttonUpdate}`}
                                </Link>
                                <Link to={``}
                                      id={"btn-delete"}
                                      className={"form-detail-btn btn btn-danger mt-2"}
                                      onClick={() => {
                                          removeLinkHandler(link)
                                      }}>
                                    {`${lang.body.linkPage.buttonDelete}`}
                                </Link>
                            </div>
                        </fieldset>
                    </form>
                </div>
            </>
        )
    }
}