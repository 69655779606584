import React, {useCallback, useContext, useEffect} from 'react'
import {useHttp} from '../hooks/http.hook'
import {AuthContext} from '../context/AuthContext'
import config from '../config.json'
import {LoaderClass} from "./LoaderClass"

export const ExitPage = () => {
    const {request, loading} = useHttp()
    const auth = useContext(AuthContext)

    const fetch = useCallback(async () => {
        let host
        window.location.host === 'localhost:3000' ? host = config.localHost : host = config.heroku
        try {
            // Обновляем базу языков из кода на сервере
            await request(
                `${host}/api/language/load`,
                'POST',
                {
                    en: 'en',
                    ru: 'ru',
                    he: 'he',
                    ge: 'ge',
                    ar: 'ar'
                })
            // Удаляем токен и выбрасываем юзера
            await request(`${host}/api/user/logout`,
                'POST',
                null,
                {
                    Authorization: `Bearer ${auth.accessToken} RefreshToken ${auth.refreshToken} DeviceId ${auth.deviceId}`
                }
            )
            auth.logout()
        } catch (e) {
            console.error(e.message)
        }
    }, [request, auth])

    useEffect(() => {
        fetch().then()
    }, [fetch])

    if (loading) {
        return <LoaderClass />
    }

    return (<></>)
}