import React, {useCallback, useContext, useEffect, useState} from "react"
import {setDataModalFromCatch, setErrorWindow, setLanguage} from "../functions"
import config from "../config.json"
import {useHttp} from "../hooks/http.hook"
import {AuthContext} from "../context/AuthContext"
import {LinksClass} from "../components/LinksClass"
import {ModalWin} from "../elements/Modal"
import {createBrowserHistory} from "history"
import {LoaderClass} from "../elements/LoaderClass"

export const RootLinksPage = () => {
    const {loading, request} = useHttp()
    const history = createBrowserHistory()

    const auth = useContext(AuthContext)
    const [documents, setDocuments] = useState({})
    const [allowed, setAllowed] = useState(true)
    const [dataModal, setDataModal] = useState({})
    const [allowedCatch, setAllowedCatch] = useState(true)
    const [dataModalCatch, setDataModalCatch] = useState({})

    setLanguage(auth.accessToken, auth.language)
    history.push(`/folder/open/${auth.rootFolder}`)

    const fetch = useCallback(async () => {
        let host
        window.location.host === 'localhost:3000' ? host = config.localHost : host = config.heroku
        try {
            const data = await request(
                `${host}/api/folder/open/${auth.rootFolder}`,
                'GET',
                null,
                {
                    Authorization: `Bearer ${auth.accessToken} RefreshToken ${auth.refreshToken} DeviceId ${auth.deviceId}`
                })
            if (data.status > 200) {
                setAllowed(false)
                setDataModal(setErrorWindow('', data.code))
            }

            setDocuments({
                name: data.name,
                parent: auth.rootFolder,
                folders: data.folders,
                links: data.links,
                notes: data.notes,
                path: data.path,
                upLevel: data.upLevel
            })

        } catch (e) {
            setAllowedCatch(false)
            setDataModalCatch(setDataModalFromCatch(e))
        }
    }, [auth, request])

    useEffect(() => {
        fetch().then()
    }, [fetch])

    if (!allowed) {
        return <ModalWin data={dataModal}/>
    }

    if (!allowedCatch) {
        return <ModalWin data={dataModalCatch}/>
    }

    if (loading) {
        return <LoaderClass/>
    }

    return (
        <>
            <LinksClass
                documents={documents}
                rootFolder={auth.rootFolder}
            />
        </>
    )

}