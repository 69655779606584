import React from "react"

export class LoaderClass extends React.Component {

    render() {

        return (
            <div className={"d-flex justify-content-center text-info mt-5"}>
                <div className={"spinner-border"} style={{width: "3rem", height: "3rem"}} role={"status"}>
                    <span className={"sr-only"}/>
                </div>
            </div>
        )
    }
}