import React, {useCallback, useContext, useEffect, useState} from 'react'
import {NavLink} from 'react-router-dom'
import {AuthContext} from '../context/AuthContext'
import {useHttp} from "../hooks/http.hook"
import config from '../config.json'
import {objectLang, set_select_by_value} from "../functions"
import {ViewWindowSearch} from "./ViewWindowSearch"

const $ = require('jquery')

export const Navbar = ({documents = {}}) => {
    const lng = objectLang().languages
    const auth = useContext(AuthContext)
    const {request} = useHttp()
    const [responseSearch, setResponseSearch] = useState(false)

    let avatarLink = auth.avatarLink

    const fetch = useCallback(async () => {
        let host
        window.location.host === 'localhost:3000' ? host = config.localHost : host = config.heroku
        try {
            const data = await request(
                `${host}/api/language/languages`,
                'GET')
            for (const i in data) {
                if (i === 'language') {
                    const lang = data[i]
                    let languages = {}
                    for (const j in lang) {
                        let language = 'en'
                        for (const k in lang) {
                            if (lang[k].lang === navigator.language.toLowerCase().split('-')[0]) {
                                language = navigator.language.toLowerCase().split('-')[0]
                            }
                        }
                        if (lang[j].lang === language) {
                            if (!localStorage.getItem('language')) {
                                localStorage.setItem('language', JSON.stringify(lang[j]))
                            }
                        }
                        languages[lang[j].lang] = lang[j]
                    }
                    localStorage.setItem('languages', JSON.stringify(languages))
                }
            }
        } catch (e) {
        }
    }, [request])

    useEffect(() => {
        if (!localStorage.getItem('languages')) {
            fetch().then()
        }
    }, [fetch])

    const changeSelect = (event) => {
        if (!localStorage.getItem('page')) {
            localStorage.setItem('page', 'MainPage')
        }

        localStorage.setItem('selectLang', event.target.value)
        const languages = JSON.parse(localStorage.getItem('languages'))
        const currLang = localStorage.getItem('selectLang')
        for (const i in languages) {
            if (i === currLang) {
                localStorage.setItem('language', JSON.stringify(languages[i]))
            }
        }
        if (auth.accessToken) {
            localStorage.setItem('isChangedLang', 'true')
        }

        set_select_by_value('language', event.target.value)
        window.location.reload()
    }

    let currentLang = {}

    if (localStorage.getItem('language')) {
        currentLang = JSON.parse(localStorage.getItem('language'))
    } else {
        fetch().then(() => {
            window.location.reload()
        })
    }

    const languages = []
    for (const i in lng) {
        languages.push([i, lng[i].navbar.optionalsLanguage])
    }

    let setValueLang = currentLang.lang

    const navbarNavClick = () => {
        const toggle = $('#navbar-nav')
        $('.navbar-nav').removeClass('ms-5')
        if (toggle.hasClass('show')) {
            toggle.removeClass('show')
        } else {
            toggle.addClass('show')
        }
    }

    if (responseSearch) {
        return (
            <>
                {<Navbar documents={documents} />}
                {<ViewWindowSearch lang={currentLang}/>}
            </>
        )
    }

    return (
        <>
            <div className={"sticky-top bg-body-tertiary navbar-expand-lg"}>

                <nav className={"navbar p-0 pt-1"} id={"navbar"}>
                    <div className={"container-fluid p-2 flex-lg-nowrap overflow-auto"}>

                        {/*Авторизованный аватар*/}
                        {auth.isAuthenticated &&
                        <div>
                            <div style={{backgroundImage: `url(${avatarLink})`}}
                                className={"avatar-auth border rounded-circle m-1 mb-xl-0"}>
                            </div>
                        </div>}

                        {/*Неавторизованный аватар*/}
                        {!auth.isAuthenticated &&
                        <div>
                            <div className={"avatar-not-auth border rounded-circle m-1 mb-xl-0"}>

                            </div>
                        </div>}

                        {/*Никнейм*/}
                        <NavLink to={"/main"}
                                 className={"nav-name text-decoration-none text-success inactive ps-2"}>
                            <div className={"overflow-hidden text-center d-flex"}>

                                {/*Авторизованный Никнейм*/}
                                {auth.isAuthenticated &&
                                <h5 id={"navUserName"} className={"d-flex justify-content-center"}>
                                    {currentLang.navbar.isAuth.title} {auth.nickName}</h5>}

                                {/*Неавторизованный Никнейм*/}
                                {!auth.isAuthenticated &&
                                <h5 id={"navTitle"} className={"d-flex justify-content-center"}>
                                    {currentLang.navbar.isNotAuth.title}
                                </h5>}
                            </div>
                        </NavLink>

                        {auth.isAuthenticated && <div>
                            <div className={"overflow-hidden text-center mb-1 pe-2"}>
                                {/*Кнопка поиска*/}
                                <button id={"navBtnSearch"}
                                        className={"btn btn-outline-primary border-0 fw-bold fs-5"}
                                        type={"button"}
                                        onClick={() => {
                                            setResponseSearch(true)
                                        }}>
                                    {currentLang.navbar.isAuth.btnSearch}
                                </button>
                            </div>
                        </div>}

                        {/*Кнопка развертывания меню в мобильнике*/}
                        <button className={"navbar-toggler"}
                                type={"button"}
                                onClick={navbarNavClick}
                                data-bs-toggle={"collapse"}
                                data-bs-target={"navbar-nav"}
                                aria-controls={"navbar-nav"}
                                aria-expanded={"false"}
                                aria-label={"Toggle navigation"}>
                            <span className={"navbar-toggler-icon"}/>
                        </button>

                        {/*Мобильное меню*/}
                        <div className={"container collapse navbar-collapse mt-2 mb-2 justify-content-between"}
                             id={"navbar-nav"}>

                            {/*Само меню*/}
                            <ul className={"navbar-nav ms-5 me-auto mb-lg-0"}>

                                {/*Селектор языков*/}
                                {<li id={"navSelLang"} className={"nav-item ps-2"}>
                                    <select
                                        className={"form-select w-auto"}
                                        name={"language"}
                                        id={"language"}
                                        onChange={changeSelect}
                                        defaultValue={setValueLang}
                                    >{languages.map((lang, index) => {
                                            return (
                                                <option
                                                    value={lang[0]}
                                                    key={index}
                                                >{lang[1][lang[0]]}
                                                </option>
                                            )
                                        }
                                    )}
                                    </select>
                                </li>}

                                {/*Главная страница*/}
                                {<li id={"navToHome"} className={"nav-item ps-2"}>
                                    <NavLink
                                        to={'/main'}
                                        className={"nav-link inactive rounded-3"}
                                        onClick={navbarNavClick}>
                                        {currentLang.navbar.toHome}
                                    </NavLink>
                                </li>}

                                {/*Страница авторизации*/}
                                {!auth.isAuthenticated && <li id={"navBtnAuth"} className={"nav-item ps-2"}>
                                    <NavLink
                                        to={'/auth'}
                                        className={"nav-link inactive rounded-3"}>
                                        {currentLang.navbar.isNotAuth.btnAuth}
                                    </NavLink>
                                </li>}

                                {/*Страница регистрации*/}
                                {!auth.isAuthenticated && <li id={"navBtnRegister"} className={"nav-item ps-2"}>
                                    <NavLink
                                        to={'/register'}
                                        onClick={navbarNavClick}
                                        className={"nav-link inactive rounded-3"}>
                                        {currentLang.navbar.isNotAuth.btnRegister}
                                    </NavLink>
                                </li>}

                                {/*Страница "Мои линки"*/}
                                {auth.isAuthenticated && <li id={"navBtnMyLinks"} className={"nav-item ps-2"}>
                                    <NavLink
                                        to={currentLang.navbar.isAuth.linkMyLinks}
                                        className={"nav-link inactive rounded-3"}
                                        aria-current={"page"}>
                                        {currentLang.navbar.isAuth.btnMyLinks}
                                    </NavLink>
                                </li>}

                                {/*Страница Публичные линки*/}
                                {currentLang.lang === 'ru' && <li id={"navBtnPublic"} className={"nav-item ps-2"}>
                                    <NavLink
                                        to={currentLang.navbar.isNotAuth.linkPublicLinks}
                                        onClick={navbarNavClick}
                                        className={"nav-link inactive rounded-3"}
                                        aria-current={"page"}>
                                        {currentLang.navbar.isNotAuth.btnPublicLinks}
                                    </NavLink>
                                </li>}

                                {/*Страница "Профиль"*/}
                                {auth.isAuthenticated && <li id={"navBtnProfile"} className={"nav-item ps-2"}>
                                    <NavLink
                                        to={'/profile'}
                                        className={"nav-link inactive rounded-3"}>
                                        {currentLang.navbar.isAuth.btnProfile}
                                    </NavLink>
                                </li>}

                                {/*Выход из профиля"*/}
                                {auth.isAuthenticated && <li id={"navBtnOut"} className={"nav-item ps-2"}>
                                    <NavLink
                                        to={'/exit'}
                                        className={"nav-link inactive rounded-3"}>
                                        {currentLang.navbar.isAuth.btnOut}
                                    </NavLink>
                                </li>}

                            </ul>

                        </div>

                    </div>
                </nav>
            </div>
        </>
    )
}
