import React, {useContext, useState} from 'react'
import {useHttp} from '../hooks/http.hook'
import {AuthContext} from '../context/AuthContext'
import {
    eyeHandler,
    objectLang,
    setDataModalFromCatch,
    setErrorWindow,
    validationEmail,
    validationPassword
} from "../functions"
import config from '../config.json'
import {Link} from "react-router-dom"
import {ModalWin} from "../elements/Modal"
import {LoaderClass} from "../elements/LoaderClass"

const $ = require("jquery")

export const AuthPage = () => {
    localStorage.setItem('page', 'AuthPage')
    // $('#navbar-nav').removeClass('show')
    const auth = useContext(AuthContext)
    const {loading, request} = useHttp()
    const [form, setForm] = useState({
        email: '', password: ''
    })
    const [allowed, setAllowed] = useState(true)
    const [dataModal, setDataModal] = useState({})
    const [allowedCatch, setAllowedCatch] = useState(true)
    const [dataModalCatch, setDataModalCatch] = useState({})
    const [deviceId, setDeviceId] = useState(crypto.randomUUID())

    const changeHandler = event => {
        const name = event.target.name
        const value = event.target.value
        setForm({...form, [name]: value})
    }

    const sendSmsHandler = async () => {

    }

    const enterAgainHandler = () => {
        window.location.reload()
    }

    const remindPassHandler = () => {
        window.location.replace('/restore')
    }

    const loginHandler = async () => {
        let host
        window.location.host === 'localhost:3000' ? host = config.localHost : host = config.heroku
        const lang = objectLang().language
        const validPassword = validationPassword(form.password)
        if (!validPassword.flag) {
            setAllowed(false)
            setDataModal(setErrorWindow('',0 ,lang.messages[`code_${validPassword.code}`]))
            return
        }
        const validEmail = validationEmail(form.email)
        if (!validEmail.flag) {
            setAllowed(false)
            setDataModal(setErrorWindow('',0 ,lang.messages[`code_${validEmail.code}`]))
            return
        }
        const encodedEmail = btoa(form.email);
        const encodePassword = btoa(validPassword.res)
        setDeviceId(deviceId)
        try {
            const data = await request(
                `${host}/api/user/login`,
                'POST',
                {
                    email: encodedEmail,
                    password: encodePassword,
                    deviceId: deviceId,
                })

            if ((data.status > 201 || data.status < 200)) {
                setAllowed(false)
                let clickOk = '',clickCancel = '', btnOK = '', btnCancel = ''
                if (data.code === 613) {
                    btnOK = lang.modal.errors.btnOK
                    clickOk = `replaceTo /verify?uid=${data.userId}`
                    btnCancel = lang.modal.errors.btnCancel
                    clickCancel = 'replaceToMain'
                }
                if (data.code === 604) {
                    clickOk = `replaceTo /restore`
                    btnOK = lang.modal.errors.btnRestore
                    btnCancel = lang.modal.errors.btnAgain
                    clickCancel = 'replaceToAuth'
                }

                setDataModal(setErrorWindow(
                    lang.modal.errors.title,
                    data.code,
                    lang.messages[`code_${data.code}`],
                    btnOK,
                    clickOk,
                    btnCancel,
                    clickCancel
                ))
                return
            }

            if (!data.isActivated) {
                window.location.replace(`/verify?uid=${data.userId}`)
            } else {
                auth.login(data.rootFolder, data.avatarLink, data.accessToken, data.refreshToken, data.userId, data.nickName, data.language, deviceId)
            }
        } catch (e) {
            setAllowedCatch(false)
            setDataModalCatch(setDataModalFromCatch(e))
        }
    }

    if (loading) {
        return <LoaderClass />
    }

    if (!allowed) {
        return (
            <>
                <ModalWin data={dataModal}/>
            </>

        )
    }

    if (!allowedCatch) {
        return (
            <ModalWin data={dataModalCatch}/>
        )
    }

    const lang = objectLang().language

    return (
        <>
            <div className={'container col-sm-9 col-xl-6 col-xxl-4'}>
                <form className={'rounded'}>
                    <fieldset className={'teal m-3 p-3'}>
                        <legend id={"legend"} className={'fs-1'}>{lang.body.authPage.title}</legend>
                        <div id={"divEmail"} className={"mb-3"}>
                            <label
                                htmlFor={"email"}
                                className={"form-label"}>
                                {lang.body.authPage.labelEmail}
                            </label>
                            <input
                                type={"text"}
                                name={"email"}
                                id={"email"}
                                value={form.email}
                                onChange={changeHandler}
                                className={"form-control"}
                                placeholder={lang.body.authPage.placeholderEmail}
                            />
                        </div>
                        <div id={"divPassword"} className={"mb-3"}>
                            <label
                                id={"label-password"}
                                htmlFor={"password"}
                                className={"form-label"}>
                                {lang.body.authPage.labelPassword}
                            </label>
                            <div className={"input-group"}>
                                <input
                                    type={"password"}
                                    name={"password"}
                                    id={"password"}
                                    value={form.password}
                                    onChange={changeHandler}
                                    className={"form-control"}
                                    placeholder={lang.body.authPage.placeholderPassword}
                                />
                                <div className={"input-group-prepend"}>
                                    <div className={"div-eye input-group-text"}>
                                        <Link to={``}
                                              className={"eye-auth"}
                                              onClick={() => {
                                                  eyeHandler($('#eye-open-close'), $('#password'))
                                              }}>
                                            <i id={"eye-open-close"} className={"fa-regular fa-eye-slash"}/>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className={"container d-flex justify-content-center"}>
                            <div className={"row"}>
                                <div className={"col-sm"}>
                                    <button
                                        id={"enter"}
                                        type={'button'}
                                        onClick={loginHandler}
                                        disabled={loading}
                                        className={"mt-2 btn btn-primary"}>
                                        {lang.body.authPage.btnLogin}
                                    </button>
                                    <button
                                        id={"sendAgain"}
                                        type={'button'}
                                        onClick={sendSmsHandler}
                                        disabled={loading}
                                        className={"mt-2 btn btn-primary d-none"}>
                                        {lang.body.authPage.getCodeAgain}
                                    </button>
                                    <button
                                        id={"enterAgain"}
                                        type={'button'}
                                        onClick={enterAgainHandler}
                                        disabled={loading}
                                        className={"mt-2 btn btn-primary d-none"}>
                                        {lang.body.authPage.inToAnotherAccount}
                                    </button>
                                    <button
                                        id={"remindPass"}
                                        type={'button'}
                                        onClick={remindPassHandler}
                                        disabled={loading}
                                        className={"mt-2 ms-2 btn btn-warning ms-1"}>
                                        {lang.body.authPage.restorePassword}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </fieldset>
                </form>
            </div>
        </>
    )
}
