import React from "react"
import {Link} from "react-router-dom"
import {clearBuffer, contextMenuOff} from "../functions"

export class MenuItemClass extends React.Component {

    render() {

        const {lang, data, removeHandler, copyItemHandler, cutItemHandler} = {
            lang: this.props.lang,
            data: this.props.data,
            removeHandler: this.props.removeHandler,
            copyItemHandler: this.props.copyItemHandler,
            cutItemHandler: this.props.cutItemHandler,
        }

        let pathOpen, pathProperties

        if (data.item === 'folder') {
            if (data.owner !== data.userId) {
                pathOpen = `/shared/folder/open/${data.id}`
            } else {
                pathOpen = `/folder/open/${data.id}`
            }
            pathProperties = `/folder/detail/${data.id}`
        }
        if (data.item === 'link') {
            pathOpen = data.link
            pathProperties = `/link/detail/${data.id}`
        }
        if (data.item === 'note') {
            pathOpen = `/note/open/${data.id}`
            pathProperties = `/note/detail/${data.id}`
        }

        return (
            <>
                <div key={`context-menu-item-${data.key}`} id={`context-menu-item-${data.key}`}
                     className={"context-menu-item d-none"}>
                    <div className={"context-menu-body p-2"}>

                        {/*Открыть, содержащую объект, папку*/}
                        {/*todo*/}

                        {/*Открыть*/}
                        {data.item === 'link' &&
                        <div id={`menu-open-item-${data.key}`}
                             className={"item btn btn-light w-100 text-start text-black rounded-0"}>
                            <Link to={pathOpen}
                                  className={'text-decoration-none text-black'}
                                  rel={"noreferrer noopener"}
                                  target={"_blank"}
                                  onClick={contextMenuOff}>
                                {lang.body.contextMenu.openItem}
                            </Link>
                        </div>}

                        {/*Открыть*/}
                        {data.item !== 'link' &&
                        <div id={`menu-open-item-${data.key}`}
                             className={"item btn btn-light w-100 text-start text-black rounded-0"}>
                            <Link to={pathOpen}
                                  className={'text-decoration-none text-black'}
                                  onClick={contextMenuOff}>
                                {lang.body.contextMenu.openItem}
                            </Link>
                        </div>}

                        {/*Вырезать*/}
                        {data.from === 'view' &&
                        <div id={`menu-move-item-${data.key}`}
                             className={"item btn btn-light w-100 text-start text-black rounded-0"}
                             onClick={(e) => {
                                 clearBuffer()
                                 copyItemHandler(data)
                                 cutItemHandler(data)
                                 contextMenuOff(e)
                             }}>
                            {lang.body.contextMenu.moveToFolder}
                        </div>}

                        {/*Копировать*/}
                        {data.from === 'view' &&
                        <div id={`menu-copy-item-${data.key}`}
                             className={"item btn btn-light w-100 text-start text-black rounded-0"}
                             onClick={(e) => {
                                 clearBuffer()
                                 copyItemHandler(data)
                                 contextMenuOff(e)
                             }}>
                            {lang.body.contextMenu.copyToFolder}
                        </div>}

                        {/*Удалить*/}
                        {data.from === 'view' &&
                        <div id={`menu-delete-item-${data.key}`}
                             className={"item btn btn-light w-100 text-start text-black rounded-0"}
                             onClick={removeHandler}>
                            {lang.body.contextMenu.deleteItem}
                        </div>}

                        {/*Свойства*/}
                        <div id={`menu-properties-item-${data.key}`}
                             className={"item btn btn-light w-100 text-start text-black rounded-0"}
                             onClick={() => {
                                 if (data.from === 'search') {
                                     window.location.reload()
                                 }
                             }}>
                            <Link to={pathProperties} className={'text-decoration-none text-black'}>
                                {lang.body.contextMenu.propertiesItem}
                            </Link>
                        </div>
                    </div>
                </div>
            </>
        )
    }

}