import React from 'react'
import {Link} from "react-router-dom"
import {
    changeHandler,
    formatDate,
    objectLang,
} from "../functions"

export class NoteCardClass extends React.Component {

    render() {

        const {note, removeNoteHandler} = {
            note: this.props.note,
            removeNoteHandler: this.props.removeNoteHandler
        }

        let lang = objectLang().language

        return (
            <>
                <div className={'container col col-sm-12 col-xl-6'}>
                    <form className={'rounded'}>

                        <p
                            id={"p-date-created"}
                            className={"mt-3 ms-3 mb-1"}>
                            {`${lang.body.linkPage.createdDate}`}&nbsp;
                            <strong>{formatDate(note["dateCreated"]).date}</strong>
                        </p>

                        <fieldset className={'teal m-3 p-3'}>

                            <div id={"divFrom"} className={"mb-1 text-center"}>
                                <h3
                                    id={"owner"}
                                    className={`mb-3`}
                                    placeholder={`${lang.body.linkPage.placeholderSiteLink}`}>
                                    {`${note.name}`}
                                </h3>
                            </div>

                            <div id={"divName"} className={"mb-3"}>
                                <label
                                    id={"label-name"}
                                    htmlFor={"name"}
                                    className={"form-label"}>
                                    {`${lang.body.notePage.labelNoteTitle}`}
                                </label>
                                <input
                                    disabled
                                    type={"text"}
                                    name={"name"}
                                    id={"name"}
                                    defaultValue={`${note.name}`}
                                    onChange={changeHandler}
                                    className={`form-control`}
                                    placeholder={`${lang.body.notePage.placeholderNoteTitle}`}
                                />
                            </div>
                            <div id={"divDescription"} className={"mb-3"}>
                                <label
                                    id={"label-description"}
                                    htmlFor={"description"}
                                    className={"form-label"}>
                                    {`${lang.body.notePage.labelNoteBody}`}
                                </label>
                                <textarea
                                    disabled
                                    name={"body"}
                                    id={"body"}
                                    defaultValue={note.body}
                                    onChange={changeHandler}
                                    className={`form-control`}
                                    placeholder={`${lang.body.notePage.placeholderNoteBody}`}
                                />
                            </div>
                            <div className={"ms-1 me-1 row row-cols-xl-5 gap-xl-2 justify-content-center"}>
                                <Link to={localStorage.getItem('pathBack')}
                                      id={"btn-back"}
                                      className={"form-detail-btn btn btn-info mt-2"}>
                                    {`${lang.body.notePage.buttonBack}`}
                                </Link>
                                <Link to={`/note/open/${note["_id"]}`}
                                      id={"btn-open"}
                                      className={"form-detail-btn btn btn-warning mt-2"}>
                                    {`${lang.body.notePage.buttonOpen}`}
                                </Link>
                                <Link to={``}
                                      id={"btn-delete"}
                                      className={"form-detail-btn btn btn-danger mt-2"}
                                      onClick={removeNoteHandler}>
                                    {`${lang.body.notePage.buttonDelete}`}
                                </Link>
                            </div>
                        </fieldset>
                    </form>
                </div>
            </>
        )
    }
}
