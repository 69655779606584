import {useState, useCallback, useEffect} from 'react'
import {deleteCookies} from "../functions"

export const useAuth = () => {
    const [rootFolder, setRootFolder] = useState(null)
    const [avatarLink, setAvatarLink] = useState(null)
    const [accessToken, setAccessToken] = useState(null)
    const [refreshToken, setRefreshToken] = useState(null)
    const [nickName, setNickName] = useState(null)
    const [language, setLanguage] = useState(null)
    const [ready, setReady] = useState(false)
    const [userId, setUserId] = useState(null)
    const [deviceId, setDeviceId] = useState(null)

    const login = useCallback((rootFolder, avatarLink, jwtAccessToken, jwtRefreshToken, id, nick, lang, deviceId) => {
        setRootFolder(rootFolder)
        setAvatarLink(avatarLink)
        setAccessToken(jwtAccessToken)
        setRefreshToken(jwtRefreshToken)
        setUserId(id)
        setNickName(nick)
        setLanguage(lang)
        if (!deviceId) {
            deviceId = crypto.randomUUID()
        }
        setDeviceId(deviceId)
        localStorage.setItem('userData', JSON.stringify({
            rootFolder: rootFolder,
            avatarLink: avatarLink,
            userId: id,
            accessToken: jwtAccessToken,
            refreshToken: jwtRefreshToken,
            nickName: nick,
            language: lang,
            deviceId: deviceId
        }))
    }, [])


    const logout = useCallback(() => {
        setRootFolder(null)
        setAvatarLink(null)
        setAccessToken(null)
        setRefreshToken(null)
        setUserId(null)
        setNickName(null)
        setLanguage(null)
        setDeviceId(null)
        deleteCookies()
    }, [])

    useEffect(() => {
        const data = JSON.parse(localStorage.getItem('userData'))

        if (data && data.accessToken) {
            login(
                data.rootFolder,
                data.avatarLink,
                data.accessToken,
                data.refreshToken,
                data.userId,
                data.nickName,
                data.language,
                data.deviceId,
            )
        }
        setReady(true)
    }, [login])

    return {login, logout, rootFolder, avatarLink, accessToken, refreshToken, userId, ready, nickName, language, deviceId}
}
