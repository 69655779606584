import React, {useContext, useState} from 'react'
import {objectLang, setDataModalFromCatch, setErrorWindow} from "../functions"
import {useHttp} from "../hooks/http.hook"
import {AuthContext} from "../context/AuthContext"
import config from "../config.json"
import {ModalWin} from "./Modal"
import {MenuCreateItemClass} from "../components/MenuCreateItemClass"
import {LoaderClass} from "./LoaderClass"

export const MenuCreateItemPage = ({parent}) => {

    let lang = objectLang().language
    const {request, loading} = useHttp()
    const auth = useContext(AuthContext)
    const [allowed, setAllowed] = useState(true)
    const [dataModal, setDataModal] = useState({})
    const [allowedCatch, setAllowedCatch] = useState(true)
    const [dataModalCatch, setDataModalCatch] = useState({})
    let host
    window.location.host === 'localhost:3000' ? host = config.localHost : host = config.heroku

    const clickPasteFolderHandler = async (folderData, parentIdPaste, parentIdCut, isCutFolder) => {
        try {
            const data = await request(
                `${host}/api/folder/paste?folderId=${folderData.id}`,
                'POST',
                {parentFID: parentIdPaste},
                {
                    Authorization: `Bearer ${auth.accessToken} RefreshToken ${auth.refreshToken} DeviceId ${auth.deviceId}`
                })

            if (data.status > 201) {
                setAllowed(false)
                setDataModal(setErrorWindow('',
                    data.code,
                    lang.messages[`code_${data.code}`],
                    lang.modal.errors.btnOK,
                    localStorage.getItem('pathBack'))
                )
                window.location.replace('/modal')
            }
            if (data.status === 201) {
                localStorage.removeItem('copyFolder')
                if (isCutFolder) {
                    window.location.replace(`/folder/remove/${folderData.id}`)
                } else {
                    window.location.replace(`/folder/open/${parentIdPaste}`)
                }
            }
        } catch (e) {
            setAllowedCatch(false)
            setDataModalCatch(setDataModalFromCatch(e))
        }
    }

    const clickPasteLinkHandler = async (linkData, parentIdPaste, parentIdCut, isCutLink) => {
        try {
            const data = await request(
                `${host}/api/link/copy/${linkData.id}`,
                'POST',
                {parentFID: parentIdPaste},
                {
                    Authorization: `Bearer ${auth.accessToken} RefreshToken ${auth.refreshToken} DeviceId ${auth.deviceId}`
                })

            if (data.status > 201) {
                setAllowed(false)
                setDataModal(setErrorWindow('', data.code, lang.messages[`code_${data.code}`], lang.modal.errors.btnOK, localStorage.getItem('pathBack')))
            }
            if (data.status === 201) {
                localStorage.removeItem('copyLink')
                if (isCutLink) {
                    window.location.replace(`/link/remove/${linkData.id}`)
                } else {
                    window.location.replace(`/folder/open/${parentIdPaste}`)
                }
            }
        } catch (e) {
            setAllowedCatch(false)
            setDataModalCatch(setDataModalFromCatch(e))
        }
    }

    const clickPasteNoteHandler = async (noteData, parentIdPaste, parentIdCut, isCutNote) => {
        try {
            const data = await request(
                `${host}/api/note/copy/${noteData.id}`,
                'POST',
                {parentFID: parentIdPaste},
                {
                    Authorization: `Bearer ${auth.accessToken} RefreshToken ${auth.refreshToken} DeviceId ${auth.deviceId}`
                })

            if (data.status > 201) {
                setAllowed(false)
                setDataModal(setErrorWindow('', data.code, lang.messages[`code_${data.code}`], lang.modal.errors.btnOK, localStorage.getItem('pathBack')))
            }
            if (data.status === 201) {
                localStorage.removeItem('copyNote')
                if (isCutNote) {
                    window.location.replace(`/note/remove/${noteData.id}`)
                } else {
                    window.location.replace(`/folder/open/${parentIdPaste}`)
                }
            }
        } catch (e) {
            setAllowedCatch(false)
            setDataModalCatch(setDataModalFromCatch(e))
        }
    }

    if (loading) {
        return <LoaderClass />
    }

    if (!allowed) {
        return <ModalWin data={dataModal}/>
    }

    if (!allowedCatch) {
        return <ModalWin data={dataModalCatch}/>
    }

    return (
        <>
            {<MenuCreateItemClass
                lang={lang}
                auth={auth}
                parent={parent}
                clickPasteFolderHandler={clickPasteFolderHandler}
                clickPasteLinkHandler={clickPasteLinkHandler}
                clickPasteNoteHandler={clickPasteNoteHandler}
            />}
        </>
    )
}