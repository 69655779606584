import React from 'react'
import {Link} from "react-router-dom"
import Row from "react-bootstrap/Row"
import uploadAvatar from "../images/add-avatar.png"

export class UploadAvatarClass extends React.Component {

    render() {

        const {lang, avatars, avatarLink} = {
            lang: this.props.lang,
            avatars: this.props.avatars,
            avatarLink: this.props.avatarLink
        }

        // console.log(avatarLink)
        // console.log(avatars)

        return (
            <>
                <div className={'container rounded bg-white mt-md-3 pt-3 mb-5'}>
                    <div className={'d-flex justify-content-center'}>
                        <h3>
                            Выбираем аватар здесь
                        </h3>
                    </div>
                    <div className={'container d-flex justify-content-center'}>
                        <div style={{backgroundImage: `url(${avatarLink})`}}
                             className={'avatar-image-current text-center rounded-5 mt-4'}/>
                    </div>

                    <div className={'container mt-5 mb-5'}>
                        <div className={'m-0-auto'}>
                            <div className={'avatars d-flex'}>
                                <Row id={'avatars'} xs={2} md={3} lg={6} className={"d-flex justify-content-center w-100"}>
                                    <div key={`avatar-add`} className={"row mt-4"}>
                                        <div className={"d-flex justify-content-center position-relative"}>
                                            <Link to={`/upload-avatar-image`}>
                                                <div className={'hand-click'}>
                                                    <img src={uploadAvatar}
                                                         className={"img-avatar w-100 img-thumbnail rounded-4 m-0"}
                                                         alt={"..."}/>
                                                </div>
                                            </Link>
                                        </div>
                                    </div>

                                    {avatars && avatars.map((avatar, i) => {
                                        let icon = avatar["thumb_url"]
                                        const key = `avatar-${i}`
                                        return (
                                            <div key={key} className={"row mt-4"}>
                                                <div className={"d-flex justify-content-center position-relative"}>
                                                    <Link to={`/upload-avatar-file?avatar=${avatar.from}`}>
                                                        <div className={'hand-click'}>
                                                            <img src={icon}
                                                                 className={"img-avatar w-100 img-thumbnail rounded-4 m-0"}
                                                                 alt={"..."}/>
                                                        </div>
                                                    </Link>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </Row>
                            </div>
                        </div>
                    </div>

                    <div className={"ms-1 me-1 row row-cols-xl-5 gap-xl-2 justify-content-center"}>
                        <Link to={'/profile'}
                              id={"btn-back"}
                              className={"form-detail-btn btn btn-info mt-2 mb-4 fs-3 w-auto ps-5 pe-5"}>
                            {`${lang.body.linkPage.buttonBack}`}
                        </Link>
                    </div>
                </div>
            </>
        )
    }
}