import React, {useCallback, useContext, useEffect, useState} from 'react'
import {useHttp} from '../hooks/http.hook'
import {AuthContext} from '../context/AuthContext'
import config from '../config.json'
import {LoaderClass} from "./LoaderClass"
import {UploadAvatarClass} from "../components/UploadAvatarClass"
import {objectLang, setDataModalFromCatch, setErrorWindow} from "../functions"
import {ModalWin} from "./Modal"

export const UploadAvatar = () => {
    const {request, loading} = useHttp()
    const [allowed, setAllowed] = useState(true)
    const [dataModal, setDataModal] = useState({})
    const [allowedCatch, setAllowedCatch] = useState(true)
    const [dataModalCatch, setDataModalCatch] = useState({})
    const [avatars, setAvatars] = useState([])

    const auth = useContext(AuthContext)
    const lang = objectLang().language

    localStorage.setItem('pathBack', `/upload-avatar`)

    const fetch = useCallback(async () => {
        let host
        window.location.host === 'localhost:3000' ? host = config.localHost : host = config.heroku
        try {
            const data = await request(
                `${host}/api/cloudinary/get-avatars`,
                'GET',
                null,
                {
                    Authorization: `Bearer ${auth.accessToken} RefreshToken ${auth.refreshToken} DeviceId ${auth.deviceId}`
                })

            if (data.status > 200) {
                setAllowed(false)
                setDataModal(setErrorWindow())
            }

            setAvatars(data['avatars'])

        } catch (e) {
            setAllowedCatch(false)
            setDataModalCatch(setDataModalFromCatch(e))
        }
    }, [request, auth])

    useEffect(() => {
        fetch().then()
    }, [fetch])

    if (loading) {
        return <LoaderClass/>
    }

    if (!allowed) {
        return <ModalWin data={dataModal}/>
    }

    if (!allowedCatch) {
        return <ModalWin data={dataModalCatch}/>
    }

    return (
        <>
            <UploadAvatarClass
                lang={lang}
                avatars={avatars}
                avatarLink={auth.avatarLink}
            />
        </>
    )
}