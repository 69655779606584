import React from 'react'
import {PathLineClass} from "../elements/PathLineClass"
import uploadIcon from "../images/add-white.png"
import {objectLang} from "../functions"

export class UploadSingleImageClass extends React.Component {

    render() {

        const {documents, changeNameHandler, handleInputChange, handleSubmit, disabledButton, progressBarOpacity, allowedTypes} = {
            documents: this.props.documents,
            changeNameHandler: this.props.changeNameHandler,
            handleInputChange: this.props.handleInputChange,
            handleSubmit: this.props.handleSubmit,
            disabledButton: this.props.disabledButton,
            progressBarOpacity: this.props.progressBarOpacity,
            allowedTypes: this.props.allowedTypes,
        }

        const lang = objectLang().language

        return (
            <>
                {<PathLineClass documents={documents}/>}
                <div className={'container col-sm-9 col-xl-6 col-xxl-4 text-center'}>
                    <form id={'form_file'} className={'rounded'} encType={'multipart/form-data'} method={"post"}>
                        <fieldset className={'teal m-3 p-3'}>
                            <legend id={"legend"} className={'fs-1 mb-5'}>{lang.body.uploadFilePage.title}</legend>
                            <div className={"mb-3 mt-3 d-flex"}>
                                <input
                                    placeholder={lang.body.uploadFilePage.placeholderName}
                                    type={"text"}
                                    className={"form-control"}
                                    id={"file-name"}
                                    onChange={changeNameHandler}
                                />
                            </div>
                            <div id={"divUploadFile"}>
                                <label
                                    id={"label-upload-file"}
                                    htmlFor={"input__file"}
                                    className={"input__file-button rounded-2"}>
                            <span className={"input__file-icon-wrapper"}>
                                <img className={"input__file-icon"} src={uploadIcon} alt="..." width={"25"}/>
                            </span>
                                    <span
                                        className={"input__file-button-text"}>{lang.body.uploadFilePage.labelTextOne}</span>
                                </label>
                                <div className={"input-group d-none"}>
                                    <input
                                        id={"input__file"}
                                        name={"file"}
                                        type={"file"}
                                        accept={allowedTypes}
                                        className={"form-control mt-2 rounded-2 input input__file d-flex me-2"}
                                        onChange={handleInputChange}
                                    />
                                </div>
                            </div>

                            <progress className={"w-100 mt-3"}
                                      style={{opacity: progressBarOpacity}}
                                      value="0"
                                      max="100">
                            </progress>

                            <div className={"d-flex justify-content-center"}>
                                <button
                                    id={'path-back'}
                                    className={"col-4 col-md-3 btn btn-info border-0 me-2"}
                                    type={"button"}
                                    onClick={() => {
                                        window.location.replace(localStorage.getItem('pathBack'))
                                    }}>
                                    {`${lang.body.uploadFilePage.buttonBack}`}
                                </button>
                                <button
                                    id={'submit'}
                                    className={"col-6 col-md-4 btn btn-success border-0"}
                                    type={"submit"}
                                    onClick={handleSubmit}
                                    disabled={disabledButton}>
                                    {lang.body.uploadFilePage.buttonSend}
                                </button>
                            </div>

                        </fieldset>
                    </form>

                    <p>
                        <strong>Uploading status:</strong>
                        <span id={"statusMessage"}>🤷‍♂ Nothing's uploaded</span>
                    </p>

                </div>
            </>
        )
    }
}