import React from "react"
import {objectLang} from "../functions"
import Row from "react-bootstrap/Row"
import {Link} from "react-router-dom"
import config from "../config.json"

export class PathLineClass extends React.Component {

    render() {

        let documents, search
        this.props.documents ? documents = this.props.documents : documents = {}
        this.props.search ? search = this.props.search : search = false

        const lang = objectLang().language
        const paths = documents.path

        let classNameRow = 'd-flex justify-content-center m-0 bg-light pt-1 pb-1 border'
        if (search) {
            classNameRow = 'd-flex justify-content-center m-0 pt-1 pb-1'
        }

        localStorage.setItem('documents', JSON.stringify(documents))

        return (
            <>
                {/* Путь к папке */}
                {<Row id={"row-path"} xs={1} className={classNameRow}>
                    <div className={'d-flex align-items-center m-auto'}>
                        <nav aria-label="breadcrumb" id={"nav-breadcrumb"} className={'d-flex align-items-center'}>
                            <ol className="breadcrumb m-auto">
                                <li key={"0-Path"} className={"first-li breadcrumb-item"}>
                                    <Link to={`/main`}
                                          className={'link-breadcrumb text-decoration-none text-success fw-bold rounded-3 ps-2 pe-2 pb-1'}>
                                        {`${lang.navbar.toHome}`}
                                    </Link>
                                </li>
                                {paths && paths.map((arr, index) => {
                                        const key = `${index + 1}-Path`
                                        let name = arr[0]
                                        if (name === 'root') {
                                            name = lang.body.myLinksPage.title
                                        }
                                        if (name === 'root-public') {
                                            name = lang.body.myLinksPage.titlePublicLinks
                                        }
                                        if (name === 'shared') {
                                            name = lang.body.sharedPage.title
                                        }
                                        return (
                                            <li key={key} className={"breadcrumb-item"}>
                                                <b className={'hand-click link-breadcrumb text-decoration-none text-success fw-bold rounded-3 ps-2 pe-2 pb-1'}
                                                   onClick={() => {
                                                       if (search) {
                                                           window.location.reload()
                                                       }
                                                   }}>
                                                    {name === lang.body.myLinksPage.titlePublicLinks &&
                                                    <Link to={`/folder/open-public/${config.trialRootFolderId}`}
                                                          className={'path-link text-decoration-none text-success fw-bold'}>
                                                        {`${name}`}
                                                    </Link>}
                                                    {name !== lang.body.myLinksPage.titlePublicLinks &&
                                                    documents.path[0][0] === 'root-public' &&
                                                    <Link to={`/folder/open-public/${arr[1]}`}
                                                          className={'path-link text-decoration-none text-success fw-bold'}>
                                                        {`${name}`}
                                                    </Link>}
                                                    {documents.path[0][0] !== 'root-public' &&
                                                    <Link to={`/folder/open/${arr[1]}`}
                                                          className={'path-link text-decoration-none text-success fw-bold'}>
                                                        {`${name}`}
                                                    </Link>}
                                                </b>
                                            </li>
                                        )
                                    }
                                )}
                            </ol>
                        </nav>
                    </div>
                </Row>}
            </>

        )
    }

}