import React, {useState} from 'react'
import {useHttp} from '../hooks/http.hook'
import {
    eyeHandler,
    getQueryParams,
    objectLang,
    setDataModalFromCatch,
    setErrorWindow,
    validationPassword
} from "../functions"
import config from '../config.json'
import {ModalWin} from "../elements/Modal"
import {Link} from "react-router-dom"
import {LoaderClass} from "../elements/LoaderClass"

const $ = require("jquery")

export const ChangePasswordPage = () => {
    // $('#navbar-nav').removeClass('show')
    const {loading, request} = useHttp()
    const [form, setForm] = useState({password: ''})
    const [allowed, setAllowed] = useState(true)
    const [dataModal, setDataModal] = useState({})
    const [allowedCatch, setAllowedCatch] = useState(true)
    const [dataModalCatch, setDataModalCatch] = useState({})

    const changeHandler = event => {
        const name = event.target.name
        const value = event.target.value
        setForm({...form, [name]: value})
    }

    const sendHandler = async () => {
        let host
        window.location.host === 'localhost:3000' ? host = config.localHost : host = config.heroku
        const lang = objectLang().language
        const validPassword = validationPassword(form.password)
        if (!validPassword.flag) {
            setAllowed(false)
            setDataModal(setErrorWindow(
                '',
                0,
                lang.messages[`code_${validPassword.code}`],
                lang.modal.errors.btnTryAgain,
                '',
                '',
                'replaceTo /main'
            ))
            return
        }
        const encodedPassword = btoa(form.password)
        try {
            const data = await request(
                `${host}/api/user/changePassword`,
                'POST',
                {
                    password: encodedPassword,
                    restoreCode: getQueryParams().restore_code
                })

            if ((data.status > 200)) {
                setAllowed(false)
                setDataModal(setErrorWindow('', data.code, ''))
            }

            if ((data.code === 6015)) {
                setAllowed(false)
                setDataModal(setErrorWindow(
                    lang.modal.errors.title,
                    data.code,
                    lang.messages[`code_${data.code}`],
                    lang.modal.errors.btnAuth,
                    'replaceToAuth',
                    lang.modal.errors.btnTryAgain,
                    'replaceTo /restore'
                ))
            }

            if ((data.code === 615)) {
                setAllowed(false)
                setDataModal(setErrorWindow(
                    lang.modal.titles.excellent,
                    data.code,
                    lang.messages[`code_${data.code}`],
                    lang.modal.errors.btnAuth,
                    'replaceToAuth',
                    lang.modal.errors.btnTryAgain,
                    'replaceTo /restore'
                ))
            }

        } catch (e) {
            setAllowedCatch(false)
            setDataModalCatch(setDataModalFromCatch(e))
        }
    }

    if (loading) {
        return <LoaderClass/>
    }

    if (!allowed) {
        return (
            <ModalWin data={dataModal}/>
        )
    }

    if (!allowedCatch) {
        return (
            <ModalWin data={dataModalCatch}/>
        )
    }

    const lang = objectLang().language

    return (
        <>
            <div className={'container col-sm-9 col-xl-6 col-xxl-4'}>
                <form className={'rounded'}>
                    <fieldset className={'teal m-3 p-3'}>
                        <legend id={"legend"} className={'fs-1'}>{lang.body.changePasswordPage.title}</legend>
                        <div id={"divEmail"} className={"mb-3"}>
                            <label
                                htmlFor={"password"}
                                className={"form-label"}>
                                {lang.body.changePasswordPage.labelPassword}
                            </label>
                            <div className={"input-group"}>
                                <input
                                    type={"password"}
                                    name={"password"}
                                    id={"password"}
                                    value={form.password}
                                    onChange={changeHandler}
                                    className={"form-control"}
                                    placeholder={lang.body.authPage.placeholderPassword}
                                />
                                <div className={"input-group-prepend"}>
                                    <div className={"div-eye input-group-text"}>
                                        <Link to={`/change-password?restore_code=${getQueryParams().restore_code}`}
                                              className={"eye-auth"}
                                              onClick={() => {
                                                  eyeHandler($('#eye-open-close'), $('#password'))
                                              }}>
                                            <i id={"eye-open-close"} className={"fa-regular fa-eye-slash"}/>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className={"container d-flex justify-content-center"}>
                            <div className={"row"}>
                                <div className={"col-sm"}>
                                    <button
                                        id={"send-new-password"}
                                        type={'button'}
                                        onClick={sendHandler}
                                        disabled={loading}
                                        className={"mt-2 btn btn-primary"}>
                                        {lang.body.changePasswordPage.btnSend}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </fieldset>
                </form>
            </div>
        </>
    )
}
