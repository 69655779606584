import React from 'react'
import {BrowserRouter as Router} from 'react-router-dom'
import {useRoutes} from './routes'
import {useAuth} from './hooks/auth.hook'
import {AuthContext} from './context/AuthContext'
import 'materialize-css'
import {Navbar} from "./elements/Navbar"
import {LoaderClass} from "./elements/LoaderClass"
import {contextMenuOff} from "./functions"

function App() {
    const {rootFolder, avatarLink, accessToken, refreshToken, login, logout, userId, ready, nickName, language, deviceId} = useAuth()
    const isAuthenticated = !!accessToken
    const routes = useRoutes(isAuthenticated)

    if (!ready) {
        return <LoaderClass />
    }

    return (
        <AuthContext.Provider value={{
            rootFolder,
            avatarLink,
            accessToken,
            refreshToken,
            login,
            logout,
            userId,
            isAuthenticated,
            nickName,
            language,
            deviceId
        }}>
            <Router>
                <Navbar />
                <div id={'main-field'} className={"col-12"} onClick={contextMenuOff}>
                    {routes}
                </div>
            </Router>
        </AuthContext.Provider>
    )
}

export default App
