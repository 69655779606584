import React, {useCallback, useContext, useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'
import {useHttp} from '../hooks/http.hook'
import {AuthContext} from '../context/AuthContext'
import config from '../config.json'
import {objectLang, setDataModalFromCatch, setErrorWindow} from "../functions"
import {LinksClass} from "../components/LinksClass"
import {ModalWin} from "../elements/Modal"
import {LoaderClass} from "../elements/LoaderClass"
import {createBrowserHistory} from "history"

export const FolderPage = ({isPublic = false}) => {
    localStorage.setItem('page', 'FolderPage')

    const auth = useContext(AuthContext)
    const {request, loading} = useHttp()
    const [documents, setDocuments] = useState({})
    const [allowed, setAllowed] = useState(true)
    const [dataModal, setDataModal] = useState({})
    const [allowedCatch, setAllowedCatch] = useState(true)
    const [dataModalCatch, setDataModalCatch] = useState({})
    const folderId = useParams().id

    let opening = 'open'
    if (isPublic) {
        opening = 'open-public'
    }
    const getDocsFolder = useCallback(async () => {
        const lang = objectLang().language
        let host
        window.location.host === 'localhost:3000' ? host = config.localHost : host = config.heroku
        try {
            const data = await request(
                `${host}/api/folder/${opening}/${folderId}`,
                'GET',
                null,
                {
                    Authorization: `Bearer ${auth.accessToken} RefreshToken ${auth.refreshToken} DeviceId ${auth.deviceId}`
                })

            if (data.code === 541) {
                setAllowed(false)
                setDataModal(setErrorWindow(
                    lang.modal.errors.title,
                    data.code,
                    lang.messages.code_541,
                    lang.modal.buttons.btnOK,
                    'replaceTo /main',
                    lang.modal.buttons.btnCancel,
                    `replaceTo ${localStorage.getItem("pathBack")}`
                ))
            } else {
                const history = createBrowserHistory()
                localStorage.setItem('pathBack', history.location.pathname)
            }

            setDocuments({
                name: data.name,
                parent: folderId,
                folders: data.folders,
                links: data.links,
                notes: data.notes,
                path: data.path,
                upLevel: data.upLevel,
                opening: opening
            })

        } catch (e) {
            setAllowedCatch(false)
            setDataModalCatch(setDataModalFromCatch(e))
        }
    }, [auth, folderId, request, opening])

    useEffect(() => {
        getDocsFolder().then()
    }, [getDocsFolder])

    if (loading) {
        return <LoaderClass/>
    }

    if (!allowed) {
        return <ModalWin data={dataModal}/>
    }

    if (!allowedCatch) {
        return <ModalWin data={dataModalCatch}/>
    }

    return (
        <>
            <LinksClass
                documents={documents}
                rootFolder={auth.rootFolder}
            />
        </>

    )
}
