import {createContext} from 'react'

function noop() {}

export const AuthContext = createContext({
  rootFolder: null,
  avatarLink: null,
  accessToken: null,
  refreshToken: null,
  userId: null,
  nickName: null,
  language: null,
  login: noop,
  logout: noop,
  isAuthenticated: false,
  deviceId: null
})
