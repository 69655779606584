import React, {useState} from 'react'
import {ModalWin} from "./Modal"
import {
    createCookiesOfName,
    objectLang,
} from "../functions"
import {MenuItemClass} from "../components/MenuItemClass"

export const MenuItem = ({data}) => {

    // console.log(JSON.stringify(data.item + '.parent = ' + data.parent))

    const [allowed, setAllowed] = useState(true)
    let lang = objectLang().language
    const [dataModal, setDataModal] = useState({})

    const removeHandler = () => {
        let description
        if (data.item === 'folder') {
            description = lang.modal.warnings.desc.deleteFolder
        } else if (data.item === 'link') {
            description = lang.modal.warnings.desc.deleteLink
        } else {
            description = lang.modal.warnings.desc.deleteNote
        }
        setAllowed(false)
        setDataModal({
            title: lang.modal.warnings.title,
            desc: description,
            btnCancel: lang.modal.warnings.btnCancel,
            btnOK: lang.modal.warnings.btnOK,
            clickOK: `replaceTo /${data.item}/remove/${data.id}`,
            clickCancel: 'reload'
        })
    }

    const copyItemHandler = (copyData) => {
        if (data.item === 'folder') {
            createCookiesOfName('copyFolder', copyData)
            document.querySelector('#context-menu-paste-folder').classList.remove('d-none')
        } else if (data.item === 'link') {
            createCookiesOfName('copyLink', copyData)
            document.querySelector('#context-menu-paste-link').classList.remove('d-none')
        } else if (data.item === 'note') {
            createCookiesOfName('copyNote', copyData)
            document.querySelector('#context-menu-paste-note').classList.remove('d-none')
        }
        document.querySelector('#context-menu-clear-buffer').classList.remove('d-none')
    }

    const cutItemHandler = (cutData) => {
        if (data.item === 'folder') {
            createCookiesOfName('cutFolder', cutData)
        } else if (data.item === 'link') {
            createCookiesOfName('cutLink', cutData)
        } else if (data.item === 'note') {
            createCookiesOfName('cutNote', cutData)
        }
        document.querySelector('#context-menu-clear-buffer').classList.remove('d-none')
    }

    if (!allowed) {
        return <ModalWin data={dataModal}/>
    }

    return (
        <>
            <MenuItemClass
                lang={lang}
                data={data}
                removeHandler={removeHandler}
                copyItemHandler={copyItemHandler}
                cutItemHandler={cutItemHandler}
            />
        </>
    )
}