import React from "react"
import {Link} from "react-router-dom"
import {clearBuffer} from "../functions"

export class MenuCreateItemClass extends React.Component {

    render() {

        const {lang, auth, parent, clickPasteFolderHandler, clickPasteLinkHandler, clickPasteNoteHandler} = {
            lang: this.props.lang,
            auth: this.props.auth,
            parent: this.props.parent,
            clickPasteFolderHandler: this.props.clickPasteFolderHandler,
            clickPasteLinkHandler: this.props.clickPasteLinkHandler,
            clickPasteNoteHandler: this.props.clickPasteNoteHandler,
        }

        const copyFolder = JSON.parse(localStorage.getItem('copyFolder'))
        const copyLink = JSON.parse(localStorage.getItem('copyLink'))
        const copyNote = JSON.parse(localStorage.getItem('copyNote'))
        let classDNoneFolder, classDNoneLink, classDNoneNote, classDNoneClearBuffer = 'd-none'
        copyFolder ? classDNoneFolder = '' : classDNoneFolder = 'd-none'
        copyLink ? classDNoneLink = '' : classDNoneLink = 'd-none'
        copyNote ? classDNoneNote = '' : classDNoneNote = 'd-none'
        if (copyFolder || copyLink || copyNote) {
            classDNoneClearBuffer = ''
        }

        const isCutFolder = !!JSON.parse(localStorage.getItem('cutFolder'))
        const isCutLink = !!JSON.parse(localStorage.getItem('cutLink'))
        const isCutNote = !!JSON.parse(localStorage.getItem('cutNote'))

        return (
            <>
                <div key={`context-menu-create-${parent}`} id={"context-menu-create"}
                     className={"context-menu-create d-none"}>
                    <div className={"context-menu-body p-2"}>

                        {auth.isAuthenticated && <div>
                            {/*Вставить Папку*/}
                            <Link id={"context-menu-paste-folder"}
                                  to={"/loading"}
                                  className={`item btn btn-light w-100 text-start text-black rounded-0 ${classDNoneFolder}`}
                                  onClick={() => {
                                      const copyFolderNow = JSON.parse(localStorage.getItem('copyFolder'))
                                      const parentCut = copyFolderNow.parent
                                      clickPasteFolderHandler(copyFolderNow, parent, parentCut, isCutFolder)
                                  }}>
                                {lang.body.contextMenu.pasteFolder}
                            </Link>

                            {/*Вставить Ссылку*/}
                            <Link id={"context-menu-paste-link"}
                                  to={localStorage.getItem('pathBack')}
                                  className={`item btn btn-light w-100 text-start text-black rounded-0 ${classDNoneLink}`}
                                  onClick={() => {
                                      const copyLinkNow = JSON.parse(localStorage.getItem('copyLink'))
                                      const parentCut = copyLinkNow.parent
                                      clickPasteLinkHandler(copyLinkNow, parent, parentCut, isCutLink).then()
                                  }}>
                                {lang.body.contextMenu.pasteLink}
                            </Link>

                            {/*Вставить Заметку*/}
                            <Link id={"context-menu-paste-note"}
                                  to={localStorage.getItem('pathBack')}
                                  className={`item btn btn-light w-100 text-start text-black rounded-0 ${classDNoneNote}`}
                                  onClick={() => {
                                      const copyNoteNow = JSON.parse(localStorage.getItem('copyNote'))
                                      const parentCut = copyNoteNow.parent
                                      clickPasteNoteHandler(copyNoteNow, parent, parentCut, isCutNote).then()
                                  }}>
                                {lang.body.contextMenu.pasteNote}
                            </Link>

                            {/*Создать новую папку*/}
                            <div id={"context-menu-create-folder"}
                                 className={"item btn btn-light w-100 text-start text-black rounded-0"}>
                                <Link to={`/create-folder?folderId=${parent}`}
                                      className={"text-decoration-none text-black"}>
                                    {lang.body.contextMenu.createNewFolder}
                                </Link>
                            </div>

                            {/*Создать новую ссылку*/}
                            <div id={"context-menu-create-link"}
                                 className={"item btn btn-light w-100 text-start text-black rounded-0"}>
                                <Link to={`/create-link?folderId=${parent}`}
                                      className={"text-decoration-none text-black"}>
                                    {lang.body.contextMenu.createNewLink}
                                </Link>
                            </div>

                            {/*Создать новую Заметку*/}
                            <div id={"context-menu-create-note"}
                                 className={"item btn btn-light w-100 text-start text-black rounded-0"}>
                                <Link to={`/create-note?folderId=${parent}`}
                                      className={"text-decoration-none text-black"}>
                                    {lang.body.contextMenu.createNewNote}
                                </Link>
                            </div>

                            {/*Загрузить файл с девайса*/}
                            <div id={"context-menu-create-file"}
                                 className={"item btn btn-light w-100 text-start text-black rounded-0"}>
                                <Link to={`/upload-image-file?folderId=${parent}`}
                                      className={"text-decoration-none text-black"}>
                                    {lang.body.contextMenu.uploadFile}
                                </Link>
                            </div>

                            {/*Очистить буфер обмена*/}
                            <Link id={"context-menu-clear-buffer"}
                                  to={''}
                                  className={`item btn btn-light w-100 text-start text-black rounded-0 ${classDNoneClearBuffer}`}
                                  onClick={clearBuffer}>
                                {lang.body.contextMenu.clearBuffer}
                            </Link>
                        </div>}

                        {!auth.isAuthenticated && <div>
                            <div id={"context-menu-authorization"}
                                 className={"item btn btn-light w-100 text-start text-black rounded-0"}>
                                <Link to={`/auth`}
                                      className={"text-decoration-none text-black"}>
                                    {lang.body.contextMenu.auth}
                                </Link>
                            </div>
                        </div>}
                    </div>
                </div>
            </>
        )
    }
}