import React, {useCallback, useContext, useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'
import {useHttp} from '../hooks/http.hook'
import {AuthContext} from '../context/AuthContext'
import {LoaderClass} from '../elements/LoaderClass'
import config from '../config.json'
import {
    objectLang, setDataModalFromCatch,
    setErrorWindow
} from "../functions"
import {ModalWin} from "../elements/Modal"
import $ from "jquery"
import {FolderCardClass} from "../components/FolderCardClass"

export const FolderDetailPage = () => {
    localStorage.setItem('page', 'FolderDetailPage')

    let lang = objectLang().language
    const auth = useContext(AuthContext)
    const {request, loading} = useHttp()
    const [folder, setFolder] = useState({})
    const [owner, setOwner] = useState('')
    const [allowed, setAllowed] = useState(true)
    const [dataModal, setDataModal] = useState({})
    const [allowedCatch, setAllowedCatch] = useState(true)
    const [dataModalCatch, setDataModalCatch] = useState({})
    const folderId = useParams().id

    const getFolder = useCallback(async () => {
        let host
        window.location.host === 'localhost:3000' ? host = config.localHost : host = config.heroku
        try {
            const data = await request(
                `${host}/api/folder/detail/${folderId}`,
                'GET',
                null,
                {
                    Authorization: `Bearer ${auth.accessToken} RefreshToken ${auth.refreshToken} DeviceId ${auth.deviceId}`
                })

            if (data.status > 200) {
                setAllowed(false)
                setDataModal(setErrorWindow('', data.code))
            } else {
                setFolder(data.folder)
                setOwner(data.owner)
                const path = data.folder.path
                path.pop()
            }

        } catch (e) {
            setAllowedCatch(false)
            setDataModalCatch(setDataModalFromCatch(e))
        }
    }, [auth, folderId, request])

    useEffect(() => {
        getFolder().then()
    }, [getFolder])

    const updateFolderHandler = () => {
        $('#name').attr('disabled', false)
        $('#description').attr('disabled', false)
    }

    const removeFolderHandler = () => {
        setDataModal({
            title: lang.modal.warnings.title,
            desc: `${lang.modal.warnings.desc.deleteFolder}`,
            btnCancel: lang.modal.warnings.btnCancel,
            btnOK: lang.modal.warnings.btnOK,
            clickOK: `replaceTo /folder/remove/${folder['_id']}`,
            clickCancel: 'reload'
        })
        setAllowed(false)
    }

    const saveFolderHandler = async () => {
        let host
        window.location.host === 'localhost:3000' ? host = config.localHost : host = config.heroku
        try {
            const data = await request(`${host}/api/folder/update/${folderId}`, 'POST',
                {
                    parentFID: folder.parentFID,
                    name: $('#name').val(),
                    description: $('#description').val()
                },
                {
                    Authorization: `Bearer ${auth.accessToken} RefreshToken ${auth.refreshToken} DeviceId ${auth.deviceId}`
                })

            if (data.status > 201) {
                setAllowed(false)
                setDataModal(setErrorWindow('', data.code))
            }
            window.location.reload()
        } catch (e) {
            setAllowedCatch(false)
            setDataModalCatch(setDataModalFromCatch(e))
        }
    }

    if (loading) {
        return <LoaderClass/>
    }

    if (!allowed) {
        return <ModalWin data={dataModal}/>
    }

    if (!allowedCatch) {
        return <ModalWin data={dataModalCatch}/>
    }

    return (
        <>
            <div className={'container'}>
                {!loading && folder && <FolderCardClass
                    folder={folder}
                    owner={owner}
                    updateFolderHandler={updateFolderHandler}
                    removeFolderHandler={removeFolderHandler}
                    saveFolderHandler={saveFolderHandler}
                />}
            </div>
        </>

    )
}
